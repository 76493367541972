@import "./vars-mixins.scss";

// USer
._container {
  // h titles
  h1.addTopMargin {
    margin-top: 1rem;
  }
  h1.addTopMarginX3 {
    margin-top: 3rem;
  }

  p.appDescription {
    text-align: center;
    font-size: 1.2rem;
    padding: 0 1.5rem;
    b {
      color: $themeColor;
    }
  }

  .featureIcons {
    @include flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem 1rem 3rem 1rem;
    img {
      margin: 0 0.5rem;
    }
  }

  .iconPreview {
    width: 9rem;
  }

  .arrows {
    width: 3.2rem;
    margin: 1rem 0;
    &.down {
      transform: scaleY(-1);
    }
  }

  .description {
    font-size: 1.2rem;
    text-align: center;
    max-width: 1600px;
    .superTitle {
      font-weight: 650;
      margin-bottom: -1rem;
    }
  }
}

.terms {
  background: white;
  text-align: center;
  border: 2px solid $themeColor;
  max-width: 95%;
  max-width: #{"min(1300px, 95%)"};

  height: 20rem;
  overflow: auto;
  padding: 1rem 2rem;
  margin: 1rem 0;
  ul,
  ol {
    list-style-position: inside;
  }
}
input#termsRead {
  width: 1rem;
  height: 1rem;
  margin: -0.3rem 1rem 0 0;
}
.termsRead {
  text-decoration: underline;
  font-weight: 600;
  @include flex-wrap;
  label {
    max-width: 80vw;
  }
}

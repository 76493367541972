@import "./vars-mixins.scss";

// LoginUser
._container {
  margin: 1rem;
  padding-bottom: 1rem;
  flex: 1;

  // h titles
  h1 {
    color: $titleColor;
  }
  #{$h0} {
    font-weight: normal;
    padding: 0.5em 0.4em;
    text-align: center;
  }
  .italic {
    font-style: italic;
    padding: 1.5rem 0 0 0;
  }
  .italic + * {
    padding-top: 0.1em;
  }

  // Qr icon
  .qr {
    height: 5.5rem;
    margin: 0.1rem 0 1rem 0;
  }
  // Qr Reader
  .qrOverlay {
    width: 90vmin;
    @include centerOverlay(top);
    @include obscureUnder;
    .qrReader {
      width: 100%;
      div {
        border-width: 2.5rem !important;
      }
    }
    .qrToggle {
      width: 100%;
      @include flex;
      height: 7vmin;
      background: $themeColor;
      @extend %links;
    }
  }

  a.flex {
    @include flex(column);
    #{$a} {
      color: initial;
      text-decoration: initial;
    }
  }

  // Form controls
  input[type="text"][autocomplete],
  input[type="password"] {
    width: 18rem;
    text-align: center;
    border-color: #222;
    border-radius: 7px;
    color: black;
  }
}

// Failed auth
h4.failedAuth {
  text-align: center;
  padding: 1rem;
}

// Button
.help button,
._container button {
  margin: 1rem 0;
  background-color: $themeColor;
  padding: 0.25rem 2.8rem;
  border-radius: 7px;
  box-shadow: 0px 0.2rem 0.4rem 0.06rem rgba(0, 0, 0, 0.4);
}

// Help tooltip
.help {
  min-width: 30vw;
  max-width: #{"min(90vw, 800px)"};
  min-height: 30vh;
  max-height: 90vh;
  text-align: center;
  padding: 1.5rem;
  background: white;
  border-radius: 2rem 0 2rem 0;
  @include centerOverlay(top, fixed);
  @include obscureUnder;
  @include flex(column);
  p {
    font-size: 1.1rem;
  }
}

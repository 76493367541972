@import "./vars-mixins.scss";

// Hard reset
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

// root
:root {
  font-size: calc(1.4vmin + 7px); // for browsers who lack clamp
  font-size: clamp(13px, calc(1.4vmin + 7px), 18px);
}

body {
  font-family: "Ubuntu";
}

// Main styles
html,
body {
  @include full;
  overflow: auto;
} // overflow auto is necessary for small horizontal pages
main {
  width: 100%;
  min-height: 100%;
} // min height instead of height or the header is cut on small pages
main,
._container,
._container form {
  @include flex(column);
}
$borderHeadFoot: ".5rem solid #213671";
header {
  @include full-bg(4.5rem);
  border-bottom: #{$borderHeadFoot};
}
footer {
  @include full-bg(3rem);
  border-top: #{$borderHeadFoot};
}
header,
footer {
  box-sizing: border-box;
}
//header {clip-path: polygon(0 0, 100% 0, 100% 20%, 18rem 20%, 14rem 100%, 0 100%);}
#outlet {
  @include full-bg(initial, white, black);
  @include flex-wrap;
  flex: 1;
}
#{$hf} {
  @include flex;
}

// inputs
input {
  &[type="radio"],
  &[type="checkbox"] {
    -webkit-appearance: none;
    transform: rotate(-15deg);
    &:checked {
      background: $themeColor;
    }
    & + span {
      padding-left: 1rem;
    }
  }
  border: 0.1rem solid $themeColor !important;
  outline: none;
  &:focus {
    box-shadow: 0.2rem 0.2rem 0.2rem 0rem rgba(0, 0, 0, 0.2) !important;
  }
  &:hover:not(:checked) {
    border-color: gray !important;
  }
  &.form-control {
    border: 0.1rem solid gray !important;
    outline: none;
    &:hover,
    &:focus {
      border-color: $themeColor !important;
    }
  }
}

// buttons
button {
  border-radius: 1rem 0 1rem 0 !important;
  box-shadow: none !important;
}

// Adjust header image and link
header {
  #logoLink {
    display: flex;
    align-items: center;
    height: 100%;
    @include moveTo(left);
    img {
      height: 95%;
    }
  }
  a:not(#logoLink) {
    @include moveTo(right);
    text-align: right;
  }
}

// Make links white and underlined
#{$hf} {
  a {
    @extend %links;
    // Make footer exit to left
    &.LogOut {
      text-decoration: initial;
      @include moveTo(left);
      position: relative;
      transform: translateY(-0.1rem);
      &:hover {
        filter: invert(0) drop-shadow(0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.7));
      }
    }
    // Make help to right
    &.Help {
      text-decoration: initial;
      @include moveTo(right);
      margin-top: -0.2rem;
    }
  }
}

// scrollbars
::-webkit-scrollbar {
  width: 0.65rem;
}
::-webkit-scrollbar-track {
  background: #c9c9c9;
}
::-webkit-scrollbar-thumb {
  background: $themeColor;
  border-left: 1px solid $themeColor;
}
::-webkit-scrollbar-thumb:hover {
  background: $themeColor;
}

// show rotated background
#outlet {
  position: relative;
  & .rotatedWrapper {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    //-webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 20%, rgba(0,0,0,.4) 50%, rgba(0, 0, 0, 1) 80%);
    //-webkit-mask-image: radial-gradient(circle at 50% 50%, rgba(0, 0, 0, .7) 40%, rgba(0,0,0,1) 50%);
    //filter: drop-shadow(.5rem .5rem .5rem rgba(255,0,0,.05));
  }
  & .rotatedBG {
    background-image: url("../img/background.svg");
    background-size: 13rem;
    position: absolute;
    top: -100%;
    left: -100%;
    bottom: -100%;
    right: -100%;
    transform: rotate(-10deg);
  }
  & * {
    z-index: 1;
  }
}

h1 {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.5rem;
}
